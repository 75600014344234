import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const PlanningPhase = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title={t('seo_title_planning_phase')}
        description="In the planning stage of a project, the design process is crucial as it sets the foundation for the construction phase. At the same time, it's important to ensure that the IT infrastructure is set up to facilitate seamless communication and data management. Quantity takeoff and project structuring are also important aspects that establish a framework for resource allocation and scheduling, which ultimately contribute to the efficient execution of the project."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            {t('date_planning_phase')}
          </Typography>
          <Typography variant="h1" color="blue" size={40} mb={48}>
            {t('title_planning_phase')}
          </Typography>
          <Typography variant="body1" size={18}>
            {t('planning_phase_text')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('planning_phase_subtitle')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('planning_phase_text1.1')}{' '}
                <Link
                  href="https://bimforum.org/resource/lod-level-of-development-lod-specification"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {t('planning_phase_text1.2')}
                </Link>{' '}
                {t('planning_phase_text1.3')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-1.png"
                alt="BIM Modeling"
                title="BIM Modeling"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('planning_phase_subtitle2')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('planning_phase_text2.1')}{' '}
                <Link
                  href="https://www.autodesk.com/support/partners/signax/2113"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {t('planning_phase_text2.2')}
                </Link>{' '}
                {t('planning_phase_text2.3')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-2.png"
                alt="Common Data Environment deployment"
                title="Common Data Environment deployment"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('planning_phase_subtitle3')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('planning_phase_text3')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-3.png"
                alt="BIM Model audit"
                title="BIM Model audit"
                placeholder="blurred"
              />
            </div>
          </div>
          <Typography variant="h2" size={24} mt={48} mb={16}>
            {t('planning_phase_subtitle4')}
          </Typography>
          <Typography variant="body1" size={18}>
            {t('planning_phase_text4')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('planning_phase_subtitle5')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('planning_phase_text5')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/planning-phase/image-4.png"
                alt="Quantity Takeoff"
                title="Quantity Takeoff"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate(getPath('/bim-services/'))}
          >
            {t('Other BIM services')}
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default PlanningPhase

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
